import styled from 'styled-components';
import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/en-US';
import {
  addSeconds,
  format,
  isAfter,
  isBefore,
  isSameISOWeek,
} from 'date-fns';

import { forwardRef, useCallback } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import DatePicker from '../DatePicker';

if (locale && locale.options) {
    locale.options.weekStartsOn = 1;
}

const StyledPaperComponent = styled.div` 
  .MuiPickersDay-hidden {
    cursor: not-allowed;
    opacity: .5;
    pointer-events: all;
  }

  .MuiPickersCalendar-week > div {
    &:first-child {
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
    }
    &:last-child {
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  .MuiPickersCalendar-week > div:has(.MuiPickersDay-day--week-disabled) {
    background: #eee;
    pointer-events: none;

    button,
    .MuiPickersDay-hidden {
      color: #555;
      pointer-events: none;
    }
  }

  .MuiPickersCalendar-week > div:has(.MuiPickersDay-day--week-selected),
  .MuiPickersCalendar-week:hover > div:not(:has(.MuiPickersDay-day--week-disabled)) {
    background: #388e3c;

    button {
      color: #fff;
    }
  }
`;

const PaperComponent = forwardRef(function PaperComponent(props, ref) {
  const { children, className } = props;
  return (
    <StyledPaperComponent className={className} ref={ref}>
      {children}
    </StyledPaperComponent>
  );
})

const StyledDayWrapper = styled.div`
  display: flex;
`;

const DayWrapper = ({ children, day, dayInCurrentMonth, maxDate, minDate, selectedDate }) => {
  const dayDate = addSeconds(new Date(day), 1);
  return (
    <StyledDayWrapper
      className={`
        ${isBefore(dayDate, minDate) ? 'MuiPickersDay-day--week-disabled' : ''}
        ${isAfter(dayDate, maxDate) ? 'MuiPickersDay-day--week-disabled' : ''}
        ${isSameISOWeek(dayDate, selectedDate) ? 'MuiPickersDay-day--week-selected' : ''}
      `}
    >
      {children}
    </StyledDayWrapper>
  )
};

const DatePickerWeek = ({ from, error, maxDate, minDate, onChange, to, value }) => {
  const labelFunc = useCallback(() => {
    return `${format(from, 'dd MMM yyyy')} - ${format(to, 'dd MMM yyyy')}`;
  }, [from, to])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
      <DatePicker
        $isWeek
        autoOk
        error={error}
        id="date-picker"
        label="From - To"
        labelFunc={labelFunc}
        last="true"
        maxDate={maxDate}
        minDate={minDate}
        onChange={onChange}
        PopoverProps={{
          PaperProps: {
            component: PaperComponent,
          },
        }}
        renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => (
          <DayWrapper
            day={day}
            dayInCurrentMonth={dayInCurrentMonth}
            maxDate={maxDate}
            minDate={minDate}
            selectedDate={selectedDate}
          >
            {dayComponent}
          </DayWrapper>
        )}
        value={value}
        variant="inline"
      ></DatePicker>
    </MuiPickersUtilsProvider>
  )
};

export default DatePickerWeek;
