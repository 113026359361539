import { useCallback, useState, useContext, useEffect, useMemo, useRef } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import {
  addMonths,
  parseISO,
  endOfMonth,
  format,
  getWeekOfMonth,
  isAfter,
  isBefore,
  startOfMonth,
  subMonths
} from 'date-fns';

import { SensorContext, DateSelectedContext } from '../../utils/context';
import usePageUnavailable from '../../hooks/usePageUnavailable';
import Unavailable from '../../components/Unavailable';
import DonutChart from '../../components/DonutChart';
import ContentWrapper from '../../components/ContentWrapper';
import DateArrow from '../../components/DateArrow';
import PageTitle from '../../components/PageTitle';
import JsonTab from '../../components/JsonTab';
import WeekSelector from '../../components/WeekSelector';
import axios from '../../api/setup';
import DatePicker from '../../components/DatePicker';
import ApplianceBars from '../../components/ApplianceBars';
import CenteredLoader from '../../components/CenteredLoader';
import {
  SENSOR_NOT_SELECTED_ERROR_MESSAGE,
  MINIMUM_MONTH_ERROR_MESSAGE,
  REPORT_LAST_MONTH_ERROR_MESSAGE,
  SENSOR_ID_PLACEHOLDER,
  NO_DATA_FOUND_ERROR_MESSAGE,
  LOW_RES_APPLIANCES,
} from '../../utils/constants';
import { sortAppliancesDescending } from '../../utils/functions';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const Selectors = styled.div`
  display: flex;
  align-items: center;
`;

function DisaggregationWeekly() {
  const mounted = useRef(false)
  const previousMonth = useRef(endOfMonth(subMonths(new Date(), 1)));
  const { dateSelected, setDateSelected } = useContext(DateSelectedContext);
  const [selectedWeek, setSelectedWeek] = useState(0);

  useEffect(() => {
    if (mounted.current) return;

    if (isAfter(dateSelected, previousMonth.current)) {
      setDateSelected(previousMonth.current);
    }
    setSelectedWeek(getWeekOfMonth(dateSelected) - 1);

    mounted.current = true;
  }, [dateSelected, setDateSelected]);

  const sensor = useContext(SensorContext);
  const isPageUnavailable = usePageUnavailable();
  const [validationError, setValidationError] = useState('');
  const [isValid, setIsValid] = useState(false);

  const sensorId = sensor.id || SENSOR_ID_PLACEHOLDER;
  const formattedMonth = format(dateSelected, 'MM');
  const formattedYear = format(dateSelected, 'yyyy');

  const sensorFirstEventDate = useRef(null);
  const previousTwoMonths = useRef(endOfMonth(subMonths(new Date(), 2)));

  useEffect(() => {
    if (!sensor.id) {
      setIsValid(false);
      return setValidationError(SENSOR_NOT_SELECTED_ERROR_MESSAGE);
    }

    if (isAfter(startOfMonth(dateSelected), startOfMonth(previousMonth.current))) {
      setIsValid(false);
      return setValidationError(REPORT_LAST_MONTH_ERROR_MESSAGE);
    }

    if (sensor?.body?.data?.data?.first_event) {
      sensorFirstEventDate.current = new Date(sensor.body.data.data.first_event);
      if (isBefore(endOfMonth(dateSelected), sensorFirstEventDate.current)) {
        setIsValid(false);
        return setValidationError(
          MINIMUM_MONTH_ERROR_MESSAGE(
            format(sensorFirstEventDate.current, 'MMM yyyy')
          )
        );
      }
    }

    setIsValid(true);
    return setValidationError('');
  }, [dateSelected, sensor.id, sensor]);

  const response = useQuery(
    ['disaggregationWeekly', sensor.id, formattedMonth, formattedYear],
    () =>
      axios.get(
        `/sensors/${sensorId}/sm/disag/month/weeks?month=${formattedMonth}&year=${formattedYear}`
      ),
    {
      enabled: isValid,
      retry: 0,
    }
  );

  const weekList = useMemo(() => {
    if (!isValid || !response.data?.data?.total_consumption || !response.data?.data?.weeks?.length) {
      return null;
    }

    return response.data.data.weeks
      .map((item, index) => ({
        index, item: `Week ${index+1}: ${format(parseISO(item.from), 'do')} - ${format(parseISO(item.to), 'do')}`
      }));
  }, [isValid, response.data]);

  const weekTitle = weekList?.[selectedWeek]?.item ? weekList[selectedWeek].item : "Week";

  const disagData = useMemo(() => {
    if (!isValid || !response.data?.data?.total_consumption || !response.data?.data?.weeks?.[selectedWeek]?.appliances?.length) {
      return null;
    }

    const appliances = response.data.data.weeks[selectedWeek].appliances;
    const totalConsumption = response.data.data.weeks[selectedWeek].total_consumption ?? 0;

    const dataArray = appliances.map((item) => {
      return {
        key: item.appliance_key,
        title: LOW_RES_APPLIANCES[item.appliance_key]?.label ?? item.appliance_key,
        color: LOW_RES_APPLIANCES[item.appliance_key]?.color ?? LOW_RES_APPLIANCES.others.color,
        icon: LOW_RES_APPLIANCES[item.appliance_key]?.icon ?? LOW_RES_APPLIANCES.others.icon,
        consumption: +((item.consumption/1000).toFixed(2)),
        consumptionPercentage: Math.floor(item.consumption/(totalConsumption||1)*100),
      };
    });

    return {
      appliances: sortAppliancesDescending(dataArray),
      totalConsumption: +((totalConsumption/1000).toFixed(2)),
    };
  }, [isValid, response.data, selectedWeek]);

  const onChangeSelectWeek = useCallback((index) => {
    try {
      const date = response.data.data.weeks[index].from;
      const parsed = parseISO(date);
      setDateSelected(parsed);
    } catch (e) {
      console.error('Could not set the date selected, based in the API response.');
    }
  }, [response.data, setDateSelected]);

  // When we have a response from the API, we check if
  /// the selected week index is bigger than the weeks in the selected month
  /// the week has data, if it doesn't, we try to find one that has
  useEffect(() => {
    let newIndex = -1;
    if (response.data?.data?.weeks) {
      const { weeks } = response.data.data;
      if (selectedWeek >= weeks.length) {
        newIndex = weeks.length-1;
      }
      if (!weeks[newIndex >= 0 ? newIndex : selectedWeek].total_consumption) {
        newIndex = weeks.findIndex(week => week.total_consumption);
      }
    }
    if (newIndex >= 0) {
      setSelectedWeek(newIndex);
      onChangeSelectWeek(newIndex);
    } else onChangeSelectWeek(selectedWeek);
  }, [onChangeSelectWeek, response.data, selectedWeek, setSelectedWeek]);

  const disableBackward = isBefore(startOfMonth(dateSelected), sensorFirstEventDate.current);
  const disableForward = isAfter(dateSelected, previousTwoMonths.current);
  const disableBackwardWeek = weekList ? selectedWeek <= 0 : true;
  const disableForwardWeek = weekList ? selectedWeek >= (weekList.length-1) : true;

  if (isValid && isPageUnavailable) {
    return <Unavailable pageName={isPageUnavailable} />
  }

  return (
    <Wrapper>
      <ContentWrapper>
      <PageTitle
          filter={
            <Selectors>
              <DateArrow
                dir='backward'
                disabled={response.isLoading || disableBackward}
                onClick={() => setDateSelected(subMonths(dateSelected, 1))}
              />
              <DatePicker
                autoOk
                id="date-picker"
                label="Month"
                value={dateSelected}
                onChange={date => setDateSelected(date)}
                variant="inline"
                format="MMM yyyy"
                views={['year', 'month']}
                openTo="month"
                error={Boolean(validationError)}
                last="true"
                maxDate={previousMonth.current}
                minDate={sensorFirstEventDate.current}
              />
              <DateArrow
                dir='forward'
                disabled={response.isLoading || disableForward}
                onClick={() => setDateSelected(addMonths(dateSelected, 1))}
              />
              <DateArrow
                dir='backward'
                disabled={response.isLoading || !isValid || !response.data?.data?.total_consumption || disableBackwardWeek}
                onClick={() => setSelectedWeek(old => old-1)}
              />
              <WeekSelector
                title={weekTitle}
                list={weekList}
                onSelect={(index) => setSelectedWeek(index)}
                disabled={!isValid || !response.data?.data?.total_consumption}
              />
              <DateArrow
                dir='forward'
                disabled={response.isLoading || !isValid || !response.data?.data?.total_consumption || disableForwardWeek}
                onClick={() => setSelectedWeek(old => old+1)}
              />
            </Selectors>
          }
        >
          Disaggregation by week
        </PageTitle>
        <Typography paragraph>
          Returns the total consumption by appliances for a specific week.
        </Typography>
        {validationError && (
          <Typography paragraph color="error">
            {validationError}
          </Typography>
        )}
        {response.isLoading && <CenteredLoader />}
        {!validationError && response.isSuccess && disagData && (
          <DonutChart data={disagData} dataKey="consumption" margin={{ bottom: 15 }} />
        )}
        {!validationError && response.isSuccess && disagData?.appliances && (
          <ApplianceBars data={disagData.appliances} />
        )}
        {(response.isError || (!validationError && !disagData)) && (
          <Typography paragraph color="error">
            {NO_DATA_FOUND_ERROR_MESSAGE}
          </Typography>
        )}
      </ContentWrapper>
      <JsonTab
        endpoint={`GET /sensors/${sensorId}/sm/disag/month/weeks?month=${formattedMonth}&year=${formattedYear}`}
        response={response.data && response.data.data}
        isLoading={response.isLoading}
        isError={response.isError}
        isSuccess={response.isSuccess}
        isIdle={response.isIdle}
      />
    </Wrapper>
  );
}

export default DisaggregationWeekly;
