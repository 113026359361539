import { useState, useContext, useEffect, useMemo, useRef } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import {
  addSeconds,
  addWeeks,
  format,
  endOfISOWeek,
  isAfter,
  isBefore,
  parseISO,
  startOfDay,
  startOfISOWeek,
  subDays,
  subWeeks,
} from 'date-fns';

import { SensorContext, DateSelectedContext } from '../../utils/context';
import usePageUnavailable from '../../hooks/usePageUnavailable';
import Unavailable from '../../components/Unavailable';
import DonutChart from '../../components/DonutChart';
import ContentWrapper from '../../components/ContentWrapper';
import DateArrow from '../../components/DateArrow';
import PageTitle from '../../components/PageTitle';
import JsonTab from '../../components/JsonTab';
import axios from '../../api/setup';
import DatePickerWeek from '../../components/DatePickerWeek';
import ApplianceBars from '../../components/ApplianceBars';
import CenteredLoader from '../../components/CenteredLoader';
import {
  SENSOR_NOT_SELECTED_ERROR_MESSAGE,
  MINIMUM_DATE_ERROR_MESSAGE,
  TO_DATE_AFTER_FROM_DATE_ERROR_MESSAGE,
  DISAGGREGATION_YESTERDAY_ERROR_MESSAGE,
  DISAGGREGATION_SAME_DAY_ERROR_MESSAGE,
  SENSOR_ID_PLACEHOLDER,
  MONTH_RANGE_ERROR_MESSAGE,
  MILLISECONDS_IN_A_MONTH,
  NO_DATA_FOUND_ERROR_MESSAGE,
  LOW_RES_APPLIANCES,
} from '../../utils/constants';
import { sortAppliancesDescending } from '../../utils/functions';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

function DisaggregationWeek() {
  const [dates, setDates] = useState({
    from: new Date(),
    to: new Date(),
  });

  const mounted = useRef(false);
  const previousDay = useRef(startOfDay(subDays(new Date(), 1)));
  const previousWeek = useRef(endOfISOWeek(subWeeks(new Date(), 1)));
  const previousTwoWeeks = useRef(endOfISOWeek(subWeeks(new Date(), 2)));
  const { dateSelected, setDateSelected } = useContext(DateSelectedContext);

  const initDates = (date) => {
    setDates({
      from: startOfISOWeek(date),
      to: endOfISOWeek(date),
    });
  }

  useEffect(() => {
    if (mounted.current) return;

    if (isAfter(dateSelected, previousWeek.current)) {
      setDateSelected(previousWeek.current);
      initDates(previousWeek.current);
    } else initDates(dateSelected);

    mounted.current = true;
  }, [dateSelected, setDateSelected]);

  const sensor = useContext(SensorContext);
  const isPageUnavailable = usePageUnavailable();

  const [validationError, setValidationError] = useState('');
  const [isValid, setIsValid] = useState(false);

  const sensorId = sensor.id || SENSOR_ID_PLACEHOLDER;
  const formattedFromDate = format(dates.from, 'yyyy-MM-dd');
  const formattedToDate = format(dates.to, 'yyyy-MM-dd');

  const sensorFirstEventDate = useRef(null);

  useEffect(() => {
    if (!sensor.id) {
      setIsValid(false);
      return setValidationError(SENSOR_NOT_SELECTED_ERROR_MESSAGE);
    }

    if (isAfter(startOfDay(dates.from), previousDay.current)) {
      setIsValid(false);
      return setValidationError(DISAGGREGATION_YESTERDAY_ERROR_MESSAGE);
    }

    if (!sensorFirstEventDate.current && sensor?.body?.data?.data?.first_event) {
      sensorFirstEventDate.current = addSeconds(startOfISOWeek(
        parseISO(sensor.body.data.data.first_event)), 1);
    }

    if (sensorFirstEventDate.current && isBefore(dates.to, sensorFirstEventDate.current)) {
      setIsValid(false);
      return setValidationError(
        MINIMUM_DATE_ERROR_MESSAGE(
          format(sensorFirstEventDate.current, 'dd MMM yyyy')
        )
      );
    }

    if (startOfDay(dates.to) < startOfDay(dates.from)) {
      setIsValid(false);
      return setValidationError(TO_DATE_AFTER_FROM_DATE_ERROR_MESSAGE);
    }

    if (startOfDay(dates.to) - startOfDay(dates.from) >= MILLISECONDS_IN_A_MONTH) {
      setIsValid(false);
      return setValidationError(MONTH_RANGE_ERROR_MESSAGE);
    }

    if (startOfDay(dates.to) - startOfDay(dates.from) === 0) {
      setIsValid(false);
      return setValidationError(DISAGGREGATION_SAME_DAY_ERROR_MESSAGE);
    }

    setIsValid(true);
    return setValidationError('');
  }, [dates, sensor.id, sensor]);

  const handleSetDates = (value) => {
    setDates({
      from: startOfISOWeek(value),
      to: endOfISOWeek(value),
    });
    setDateSelected(value);
  };

  const updateDate = (dir) => {
    const newDate = dir === 'backwards' ?
      subWeeks(dateSelected, 1) :
      addWeeks(dateSelected, 1);

    setDates({
      from: startOfISOWeek(newDate),
      to: endOfISOWeek(newDate),
    });
    setDateSelected(newDate);
  };

  const response = useQuery(
    ['disaggregationWeek', sensor.id, formattedFromDate, formattedToDate],
    () =>
      axios.get(
        `/sensors/${sensorId}/sm/disag/week?startDate=${formattedFromDate}&endDate=${formattedToDate}`
      ),
    {
      enabled: isValid,
      retry: 0,
    }
  );

  const disagData = useMemo(() => {
    if (!isValid || !response.data?.data?.appliances?.length) {
      return null;
    }

    const appliances = response.data.data.appliances;
    const totalConsumption = response.data.data.total_consumption ?? 0;

    const dataArray = appliances.map((item) => {
      return {
        key: item.appliance_key,
        title: LOW_RES_APPLIANCES[item.appliance_key]?.label ?? item.appliance_key,
        color: LOW_RES_APPLIANCES[item.appliance_key]?.color ?? LOW_RES_APPLIANCES.others.color,
        icon: LOW_RES_APPLIANCES[item.appliance_key]?.icon ?? LOW_RES_APPLIANCES.others.icon,
        consumption: +((item.consumption/1000).toFixed(2)),
        consumptionPercentage: Math.floor(item.consumption/(totalConsumption||1)*100),
      };
    });

    return {
      appliances: sortAppliancesDescending(dataArray),
      totalConsumption: +((totalConsumption/1000).toFixed(2)),
    };
  }, [isValid, response.data]);

  const disableBackward = isBefore(dates.from, sensorFirstEventDate.current);
  const disableForward = isAfter(dates.to, previousTwoWeeks.current);

  if (isValid && isPageUnavailable) {
    return <Unavailable pageName={isPageUnavailable} />
  }

  return (
    <Wrapper>
      <ContentWrapper>
        <PageTitle
          filter={
            <div>
              <DateArrow
                dir='backward'
                disabled={response.isLoading || disableBackward}
                onClick={() => updateDate('backwards')}
              />
              <DatePickerWeek
                error={Boolean(validationError)}
                from={dates.from}
                maxDate={previousWeek.current}
                minDate={sensorFirstEventDate.current}
                onChange={handleSetDates}
                to={dates.to}
                value={dates.to}
              />
              <DateArrow
                dir='forward'
                disabled={response.isLoading || disableForward}
                onClick={() => updateDate('forwards')}
              />
            </div>
          }
        >
          Disaggregation by week
        </PageTitle>
        <Typography paragraph>
          Returns the total consumption by appliances for a specific range of dates.
        </Typography>
        {validationError && (
          <Typography paragraph color="error">
            {validationError}
          </Typography>
        )}
        {response.isLoading && <CenteredLoader />}
        {!validationError && response.isSuccess && disagData && (
          <DonutChart data={disagData} dataKey="consumption" margin={{ bottom: 15 }} />
        )}
        {!validationError && response.isSuccess && disagData?.appliances && (
          <ApplianceBars data={disagData.appliances} />
        )}
        {(response.isError || (!validationError && !disagData)) && (
          <Typography paragraph color="error">
            {NO_DATA_FOUND_ERROR_MESSAGE}
          </Typography>
        )}
      </ContentWrapper>
      <JsonTab
        endpoint={`GET /sensors/${sensorId}/sm/disag/week?startDate=${formattedFromDate}&endDate=${formattedToDate}`}
        response={response.data && response.data.data}
        isLoading={response.isLoading}
        isError={response.isError}
        isSuccess={response.isSuccess}
        isIdle={response.isIdle}
      />
    </Wrapper>
  );
}

export default DisaggregationWeek;
