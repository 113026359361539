import ChildFriendlyRoundedIcon from '@material-ui/icons/ChildFriendlyRounded';
import FaceIcon from '@material-ui/icons/Face';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';

import AirConditioningIcon from '../images/appliances/color/air-conditioning.svg';
import CookingIcon from '../images/appliances/color/cooking.svg';
import ElectricVehicleIcon from '../images/appliances/color/electric-vehicle.svg';
import FridgeIcon from '../images/appliances/color/fridge.svg';
import HeatingIcon from '../images/appliances/color/heating.svg';
import LightsAndElectronicsIcon from '../images/appliances/color/lights-and-electronics.svg';
import OthersIcon from '../images/appliances/color/others.svg';
import StandbyIcon from '../images/appliances/color/standby-always-on.svg';
import WashingAppliancesIcon from '../images/appliances/color/washing-appliances.svg';
import BoilerIcon from '../images/appliances/color/boiler.svg';
import TumbleDryerIcon from '../images/appliances/color/tumble-dryer.svg';
import CookingRadiatorIcon from '../images/appliances/color/cooking-radiator.svg';

export const INCORRECT_CREDENTIALS_ERROR_MESSAGE =
  'Username or password is incorrect';
export const SERVER_ERROR_MESSAGE = 'An error occurred, please try again later';
export const SENSOR_NOT_SELECTED_ERROR_MESSAGE = 'Sensor not selected';
export const MINIMUM_DATE_ERROR_MESSAGE = (date) =>
  `Minimum date is sensor's first event: ${date}`;
export const TO_DATE_AFTER_FROM_DATE_ERROR_MESSAGE =
  'To Date must be after From Date';
export const DISAGGREGATION_YESTERDAY_ERROR_MESSAGE =
  'Disaggregation data is available up to yesterday';
export const DISAGGREGATION_SAME_DAY_ERROR_MESSAGE =
  'If you want data for one day, go to Disaggregation by Day screen instead';
export const MONTH_RANGE_ERROR_MESSAGE =
  'The dates must be in a range of one month from each other';
export const DIFFERENT_MONTH_ERROR_MESSAGE =
  'The two dates must be in the same month';
export const REPORT_LAST_MONTH_ERROR_MESSAGE =
  'Monthly report is available up to last month';
export const MINIMUM_MONTH_ERROR_MESSAGE = (date) =>
  `Minimum month is sensor's first event: ${date}`;
export const NO_DATA_FOUND_ERROR_MESSAGE = 'No data found';

export const SENSOR_ID_PLACEHOLDER = '{sensor_id}';

export const MILLISECONDS_IN_A_MONTH = 2678400000;

export const APPLIANCES = {
  cooking: { label: 'Cooking Appliances', color: '#A74286' },
  heating: { label: 'Heating', color: '#816bb5' },
  heating_cooking: {
    label: 'Heating & Cooking Appliances',
    color: '#816bb5',
  },
  air_conditioner: {
    label: 'Air Conditioner',
    color: '#4f91a5',
  },
  fridge_freezer: {
    label: 'Fridge & Freezer',
    color: '#4271bd',
  },
  dishwasher: { label: 'Dishwasher', color: '#aaf2ee' },
  washing: { label: 'Washing Appliances', color: '#1c9991' },
  washing_machine: {
    label: 'Washing Machine',
    color: '#1c9991',
  },
  kettle: {
    label: 'Kettle',
    color: '#F06292',
  },
  tumble_dryer: {
    label: 'Tumble Dryer',
    color: '#a694d4',
  },
  boiler: { label: 'Boiler', color: '#136b66', value: 'BOILER' },
  electric_shower: {
    label: 'Electric Shower',
    color: '#52ccc5',
  },
  electric_vehicle: {
    label: 'Electric Vehicle',
    color: '#2C5AFF',
  },
  EV: {
    label: 'Electric Vehicle',
    color: '#2C5AFF',
  },
  standby: { label: 'Standby', color: '#57add9' },
  lighting_entertainment: {
    label: 'Lighting & Entertainment Appliances',
    color: '#ffcc00',
  },
  others: {
    label: 'Others',
    color: '#999',
  },
};

export const ENERGY_SAVING_RECOMMENDATIONS = {
  AVOID_TUMBLE_DRYER: {
    title: 'Avoid Tumble Dryer usage',
    description: 'Minimize tumble dryer usage when possible.',
    icon: TumbleDryerIcon,
  },
  ELECTRIC_VEHICLE_OFF_PEAK: {
    title: 'Electric Vehicle off-peak charging',
    description: 'Charge your EV during off-peak hours.',
    icon: ElectricVehicleIcon,
  },
  ISOLATE_WINDOWS: {
    title: 'We\'ve noticed you used your Heating during the nights',
    description: 'Putting your thermostat a few degrees lower will contribute to reducing energy costs. Try to keep your heating between the 18-20 °C range.',
    icon: StandbyIcon,
  },
  LOWER_ALWAYS_ON: {
    title: 'Unplugg your appliances',
    description: 'Lower Always-on by unplugging electronics to reduce standby power waste and save electricity.',
    icon: StandbyIcon,
  },
  DEFAULT: {
    title: '',
    description: 'Energy Saving Recommendation',
    icon: StandbyIcon,
  },
  OPTIMIZE_DRIVING: {
    title: 'Optimize driving',
    description: 'Practice eco-driving techniques to reduce electricity consumption while driving',
    icon: ElectricVehicleIcon,
  },
  USE_ECO: {
    title: 'Use ECO settings',
    description: 'Utilize ECO settings if available on appliances to optimize energy usage.',
    icon: StandbyIcon,
  },
  USE_MICROWAVE: {
    title: 'Heavy use of your cooking appliances',
    description: 'Use the microwave for cooking when possible to save energy compared to an electric oven.',
    icon: CookingIcon,
  },
  WASHING_MACHINE_LOW_TEMP: {
    title: 'High usage of your Washing machine',
    description: 'Use washing programs between 30 and 40ºC to save energy and prolong clothes lifespan.',
    icon: WashingAppliancesIcon,
  },
  WASHING_MACHINE_OFF_PEAK: {
    title: 'Washing Machine off-peak usage',
    description: 'Program washing appliances during off-peak hours.',
    icon: WashingAppliancesIcon,
  },
};

export const LOW_RES_APPLIANCES = {
  cooking: { label: 'Cooking', color: '#A74286', icon: CookingIcon },
  washing: { label: 'Washing Appliances', color: '#1c9991', icon: WashingAppliancesIcon },
  heating: { label: 'Heating', color: '#816bb5', icon: HeatingIcon },
  heating_cooking: {
    label: 'Heating & Cooking',
    color: '#816bb5',
    icon: HeatingIcon,
  },
  cooking_radiator: {
    label: 'Cooking & Radiator',
    color: '#93579E',
    icon: CookingRadiatorIcon,
  },
  cooking_heating: {
    label: 'Heating & Cooking',
    color: '#A74286',
    icon: CookingIcon,
  },
  lights: {
    label: 'Lights & Electronics',
    color: '#ffcc00',
    icon: LightsAndElectronicsIcon,
  },
  cooling: {
    label: 'Air Conditioning',
    color: '#4f91a5',
    icon: AirConditioningIcon,
  },
  fridge: {
    label: 'Fridge',
    color: '#4271bd',
    icon: FridgeIcon,
  },
  boiler: { label: 'Boiler', color: '#136b66', value: 'BOILER', icon: BoilerIcon },
  standby: { label: 'Standby / Always-On', color: '#57add9', icon: StandbyIcon },
  electric_vehicle: {
    label: 'Electric Vehicle',
    color: '#2C5AFF',
    icon: ElectricVehicleIcon,
  },
  EV: {
    label: 'Electric Vehicle',
    color: '#2C5AFF',
    icon: ElectricVehicleIcon,
  },
  others: {
    label: 'Others',
    color: '#999',
    icon: OthersIcon,
  },
};
// 14530
export const AGE_RANGES = {
  ZERO_TO_FIVE: {
    label: 'Zero to Five',
    icon: <ChildFriendlyRoundedIcon />,
  },
  SIX_TO_EIGHTEEN: {
    label: 'Six to Eighteen',
    icon: <FaceIcon />,
  },
  ADULT: {
    label: 'Adult',
    icon: <EmojiPeopleIcon />,
  },
  PENSIONER: {
    label: 'Pensioner',
    icon: <DirectionsWalkIcon />,
  },
}

export const SENSOR_PAGES_BY_TYPE = {
  // Sensors
  '/sensors': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER', 'LOW_RESOLUTION_METER'],
    title: 'Sensors',
  },
  '/single-sensor': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER', 'LOW_RESOLUTION_METER'],
    title: 'Sensors',
  },
  // Cost and Consumptions
  '/cost-consumption/overview-day': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER'],
    title: 'Overview by day',
  },
  '/cost-consumption/overview-thirty-minutes': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER'],
    title: 'Overview by thirty minutes',
  },
  '/cost-consumption/breakdown-period': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER'],
    title: 'Breakdown by period',
  },
  '/cost-consumption/breakdown-day': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER'],
    title: 'Breakdown by day',
  },
  '/cost-consumption/live-consumption': {
    types: ['VOLTAWARE_SENSOR'],
    title: 'Live consumption',
  },
  '/cost-consumption/carbon-footprint': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER'],
    title: 'Carbon Footprint',
  },
  // Tariffs
  '/tariffs': {
    types: ['VOLTAWARE_SENSOR'],
    title: 'Tariffs',
  },
  // Property
  '/property-details': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER', 'LOW_RESOLUTION_METER'],
    title: 'Property details',
  },
  // Appliances
  '/appliances': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER', 'LOW_RESOLUTION_METER'],
    title: 'Appliances',
  },
  // Disaggregation
  '/disaggregation': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER', 'LOW_RESOLUTION_METER'],
    title: 'Disaggregation',
  },
  '/disaggregation/day': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER'],
    title: 'Disaggregation by day',
  },
  '/disaggregation/period': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER'],
    title: 'Disaggregation by period',
  },
  '/disaggregation/thirty-minutes': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER'],
    title: 'Disaggregation by 30 min',
  },
  '/disaggregation/cycles': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER', 'LOW_RESOLUTION_METER'],
    title: 'Appliance cycles',
  },
  '/disaggregation/cycles/month': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER', 'LOW_RESOLUTION_METER'],
    title: 'Appliance cycles month',
  },
  '/disaggregation/cycles/week': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER', 'LOW_RESOLUTION_METER'],
    title: 'Appliance cycles week',
  },
  '/disaggregation/weekly': {
    types: ['LOW_RESOLUTION_METER'],
    title: 'Disaggregation by week',
  },
  '/disaggregation/monthly': {
    types: ['LOW_RESOLUTION_METER'],
    title: 'Disaggregation by month',
  },
  '/disaggregation/week': {
    types: ['LOW_RESOLUTION_METER'],
    title: 'Disaggregation by week',
  },
  // VoltaScore
  '/voltascore/monthly': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER', 'LOW_RESOLUTION_METER'],
    title: 'VoltaScore Monthly',
  },
  // Reports
  '/reports/monthly': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER', 'LOW_RESOLUTION_METER'],
    title: 'Monthly report',
  },
};
