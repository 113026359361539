import { useState, useContext, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import {
  addMonths,
  endOfMonth,
  format,
  isAfter,
  isBefore,
  startOfMonth,
  subMonths
} from 'date-fns';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';

import voltascoreLogoImg from '../../images/voltascore.png';

import { SensorContext, DateSelectedContext } from '../../utils/context';
import usePageUnavailable from '../../hooks/usePageUnavailable';
import Unavailable from '../../components/Unavailable';
import ContentWrapper from '../../components/ContentWrapper';
import DateArrow from '../../components/DateArrow';
import PageTitle from '../../components/PageTitle';
import JsonTab from '../../components/JsonTab';
import axios from '../../api/setup';
import DatePicker from '../../components/DatePicker';
import CenteredLoader from '../../components/CenteredLoader';

import {
  SENSOR_NOT_SELECTED_ERROR_MESSAGE,
  MINIMUM_DATE_ERROR_MESSAGE,
  SENSOR_ID_PLACEHOLDER,
  NO_DATA_FOUND_ERROR_MESSAGE,
  ENERGY_SAVING_RECOMMENDATIONS,
} from '../../utils/constants';

import { scoreColor, scoreIcon, scorePercent } from './utils';
import { 
  Container,
  EnergySavingRecommendations,
  Recommendation,
  Score,
  ScoreData,
  ScoreDataWrapper,
  ScorePercent,
  ScoreStatistic,
  ScoreTitle,
  Title,
  Value,
} from './styles';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const CardContainer = styled(Card)`
  margin-bottom: 10px;
`;

const Separator = styled.hr`
  width: 40px;
  height: 1px;
  border: none;
  margin: 20px 0;
  background-color: #C0C0C0;
  border-radius: 10px;
`;

function uniqueRecommendations(recommendations) {
  if (recommendations) {
    const unique = recommendations.filter((obj, index) => {
      return index === recommendations.findIndex(o => obj.key === o.key) && obj.key !== 'NO_RECOMMENDATION';
    });
    return unique;
  }

  return null;
}

function VoltaScoreMonthly() {
  const mounted = useRef(false)
  const previousMonth = useRef(endOfMonth(subMonths(new Date(), 1)));
  const { dateSelected, setDateSelected } = useContext(DateSelectedContext);

  useEffect(() => {
    if (mounted.current) return;

    if (isAfter(dateSelected, previousMonth.current)) {
      setDateSelected(previousMonth.current);
    }

    mounted.current = true;
  }, [dateSelected, setDateSelected]);

  const sensor = useContext(SensorContext);
  const isPageUnavailable = usePageUnavailable();
  const [validationError, setValidationError] = useState('');
  const [isValid, setIsValid] = useState(false);

  const sensorId = sensor.id || SENSOR_ID_PLACEHOLDER;
  const formattedMonth = format(dateSelected, 'MM');
  const formattedYear = format(dateSelected, 'yyyy');

  const sensorFirstEventDate = useRef(null);
  const previousTwoMonths = useRef(endOfMonth(subMonths(new Date(), 2)));

  useEffect(() => {
    if (!sensor.id) {
      setIsValid(false);
      return setValidationError(SENSOR_NOT_SELECTED_ERROR_MESSAGE);
    }

    if (sensor?.body?.data?.data?.first_event) {
      sensorFirstEventDate.current = new Date(sensor.body.data.data.first_event);
      if (isBefore(endOfMonth(dateSelected), sensorFirstEventDate.current)) {
        setIsValid(false);
        return setValidationError(
          MINIMUM_DATE_ERROR_MESSAGE(
            format(sensorFirstEventDate.current, 'dd MMM yyyy')
          )
        );
      }
    }

    setIsValid(true);
    return setValidationError('');
  }, [isValid, dateSelected, sensor.id, sensor]);

  const response = useQuery(
    ['voltaScoreMonthly', sensor.id, formattedMonth, formattedYear],
    () =>
      axios.get(
        `/sensors/${sensorId}/scores?year=${formattedYear}&month=${formattedMonth}`
      ),
    {
      enabled: isValid,
      retry: 0,
    }
  );

  const disableBackward = isBefore(startOfMonth(dateSelected), sensorFirstEventDate.current);
  const disableForward = isAfter(dateSelected, previousTwoMonths.current);

  if (isValid && isPageUnavailable) {
    return <Unavailable pageName={isPageUnavailable} />
  }

  const energySavingRecommendations = uniqueRecommendations(response?.data?.data?.recommendations);

  return (
    <Wrapper>
      <ContentWrapper>
        <PageTitle
          filter={
            <div>
              <DateArrow
                dir='backward'
                disabled={response.isLoading || disableBackward}
                onClick={() => setDateSelected(subMonths(dateSelected, 1))}
              />
              <DatePicker
                autoOk
                id="from-date"
                label="Date"
                value={dateSelected}
                onChange={date => setDateSelected(date)}
                variant="inline"
                format="MMM yyyy"
                views={['year', 'month']}
                openTo="month"
                error={Boolean(validationError)}
                last="true"
                maxDate={previousMonth.current}
                minDate={sensorFirstEventDate.current}
              />
              <DateArrow
                dir='forward'
                disabled={response.isLoading || disableForward}
                onClick={() => setDateSelected(addMonths(dateSelected, 1))}
              />
            </div>
          }
        >
          VoltaScore by month
        </PageTitle>
        <Typography paragraph>
          View monthly VoltaScore.
        </Typography>
        {validationError && (
          <Typography paragraph color="error">
            {validationError}
          </Typography>
        )}
        {response.isLoading && <CenteredLoader />}
        {!validationError && response.isSuccess && (
          <CardContainer>
            <Container>
              <img src={voltascoreLogoImg} alt="VoltaScore" />
              <Title>VoltaScore</Title>

              <Score>
                <strong>Your score</strong>
                <img src={scoreIcon(response.data.data.overall_score)} alt="VoltaScore" />
                <Value color={scoreColor(response.data.data.overall_score)}>
                  <span>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1}).format(response.data.data.overall_score)}</span>/5
                </Value>
              </Score>

              <p>A comparison against similar homes, your tariff information and your appliances health.</p>

              <ScoreDataWrapper>
                {/* P2P Score */}
                {(![null, undefined].includes(response?.data?.data?.p2p?.cost_score) || ![null, undefined].includes(response?.data?.data?.p2p?.consumption_score)) &&
                  <>
                    <ScoreData>
                      <ScoreTitle>P2P Score</ScoreTitle>
                      {![null, undefined].includes(response?.data?.data?.p2p?.cost_score) &&
                        <ScoreStatistic>
                          <p>Cost</p>
                          <div>
                            <Value color={scoreColor(response.data.data.p2p.cost_score)}>
                              <span>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1}).format(response.data.data.p2p.cost_score)}</span>/5
                            </Value>
                            <ScorePercent color={scoreColor(response.data.data.p2p.cost_score)}>
                              {scorePercent(response.data.data.p2p.cost_percentile)}
                            </ScorePercent>
                          </div>
                        </ScoreStatistic>
                      }
                      {![null, undefined].includes(response?.data?.data?.p2p?.consumption_score) &&
                        <ScoreStatistic>
                          <p>Consumption</p>
                          <div>
                            <Value color={scoreColor(response.data.data.p2p.consumption_score)}>
                              <span>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1}).format(response.data.data.p2p.consumption_score)}</span>/5
                            </Value>
                            <ScorePercent color={scoreColor(response.data.data.p2p.consumption_score)}>
                              {scorePercent(response.data.data.p2p.consumption_percentile)}
                            </ScorePercent>
                          </div>
                        </ScoreStatistic>
                      }
                    </ScoreData>
                  </>
                }
                {/* Historical Score */}
                {(![null, undefined].includes(response?.data?.data?.historical?.cost_score) || ![null, undefined].includes(response?.data?.data?.historical?.consumption_score)) &&
                  <>
                    <ScoreData>
                      <ScoreTitle>Historical Score</ScoreTitle>
                      {![null, undefined].includes(response?.data?.data?.historical?.cost_score) &&
                        <>
                          <ScoreStatistic>
                            <p>Cost</p>
                            <div>
                              <Value color={scoreColor(response.data.data.historical.cost_score)}>
                                <span>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1}).format(response.data.data.historical.cost_score)}</span>/5
                              </Value>
                            </div>
                          </ScoreStatistic>
                          <ScoreStatistic noBorder={true}>
                            <p>{response.data.data.historical.cost_percentage < 0 ? 'Decreased by' : 'Increased by'}</p>
                            <div>
                              <ScorePercent color={scoreColor(response.data.data.historical.cost_score)}>
                                {scorePercent(response.data.data.historical.cost_percentage, true)}
                              </ScorePercent>
                            </div>
                          </ScoreStatistic>
                        </>
                      }
                      {![null, undefined].includes(response?.data?.data?.historical?.consumption_score) &&
                        <>
                          <ScoreStatistic>
                            <p>Consumption</p>
                            <div>
                              <Value color={scoreColor(response.data.data.historical.consumption_score)}>
                                <span>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1}).format(response.data.data.historical.consumption_score)}</span>/5
                              </Value>
                            </div>
                          </ScoreStatistic>
                          <ScoreStatistic noBorder={true}>
                            <p>{response.data.data.historical.consumption_percentage < 0 ? 'Decreased by' : 'Increased by'}</p>
                            <div>
                              <ScorePercent color={scoreColor(response.data.data.historical.consumption_score)}>
                                {scorePercent(response.data.data.historical.consumption_percentage, true)}
                              </ScorePercent>
                            </div>
                          </ScoreStatistic>
                        </>
                      }
                    </ScoreData>
                  </>
                }
              </ScoreDataWrapper>
              {response?.data?.data?.recommendations?.length && (
                <>
                  <Separator />
                  <strong>Energy saving recommendation</strong>
                  <EnergySavingRecommendations>
                    {energySavingRecommendations.map((recommendation, index) => {
                      const data = ENERGY_SAVING_RECOMMENDATIONS[recommendation.key] ?? {
                        ...ENERGY_SAVING_RECOMMENDATIONS.DEFAULT,
                        title: recommendation.key,
                      };
                      return (
                        <Recommendation key={`energy-saving-recommendation-${index}`}>
                          <img src={data.icon} alt={recommendation.key} />
                          <div>
                            <strong>{data.title}</strong>
                            <p>{data.description}</p>
                          </div>
                        </Recommendation>
                      );
                    })}
                  </EnergySavingRecommendations>
                </>
              )}
            </Container>
          </CardContainer>
        )}
        {response.isError && (
          <Typography paragraph color="error">
            {NO_DATA_FOUND_ERROR_MESSAGE}
          </Typography>
        )}
      </ContentWrapper>
      <JsonTab
        endpoint={`GET /sensors/${sensorId}/scores?month=${formattedMonth}&year=${formattedYear}`}
        response={response.data && response.data.data}
        isLoading={response.isLoading}
        isError={response.isError}
        isSuccess={response.isSuccess}
        isIdle={response.isIdle}
      />
    </Wrapper>
  );
}

export default VoltaScoreMonthly;
