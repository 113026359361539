import Typography from '@material-ui/core/Typography';

import ContentWrapper from '../../components/ContentWrapper';
import PageTitle from '../../components/PageTitle';

function Main() {
  return (
    <ContentWrapper>
      <PageTitle>HTTP Services API</PageTitle>
      <Typography paragraph>
        The Services API is suited for integrations with external third-part
        systems, or extending current functionality.
      </Typography>
      <Typography paragraph>
        It allows integrating services to manage a group of sensors with a
        variety of operations like sign up new users, get sensor information,
        and configure call-backs.
      </Typography>
      <Typography paragraph>
        This API is organised around JSON and REST.
      </Typography>
      <PageTitle>Base API URL</PageTitle>
      <Typography>https://smart-meter-reseller-api.voltaware.com</Typography>
    </ContentWrapper>
  );
}

export default Main;
