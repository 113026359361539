import { useState, useContext, useEffect, useMemo, useRef } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import {
  addMonths,
  endOfMonth,
  format,
  isAfter,
  isBefore,
  startOfMonth,
  subMonths
} from 'date-fns';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { SensorContext, DateSelectedContext } from '../../utils/context';
import usePageUnavailable from '../../hooks/usePageUnavailable';
import Unavailable from '../../components/Unavailable';
import ContentWrapper from '../../components/ContentWrapper';
import DateArrow from '../../components/DateArrow';
import PageTitle from '../../components/PageTitle';
import JsonTab from '../../components/JsonTab';
import axios from '../../api/setup';
import DatePicker from '../../components/DatePicker';
import Card from '../../components/Card';
import Cycles from '../../components/Cycles';
import CenteredLoader from '../../components/CenteredLoader';
import {
  SENSOR_NOT_SELECTED_ERROR_MESSAGE,
  MINIMUM_MONTH_ERROR_MESSAGE,
  SENSOR_ID_PLACEHOLDER,
  NO_DATA_FOUND_ERROR_MESSAGE,
  APPLIANCES as REGULAR_APPLIANCES,
  LOW_RES_APPLIANCES,
  REPORT_LAST_MONTH_ERROR_MESSAGE,
} from '../../utils/constants';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const CardContainer = styled(Grid)`
  margin-bottom: 10px;
`;

const PAYLOAD_PROP_NAMES = {
  LOW_RESOLUTION_METER: {
    'TOTAL_CONSUMPTION': 'total_consumption',
    'APPLIANCE_KEY': 'appliance_key',
    'FROM': 'from',
    'TO': 'to',
    'ENERGY_CONSUMPTION': 'consumption',
  },
  DEFAULT: {
    'TOTAL_CONSUMPTION': 'totalConsumption',
    'APPLIANCE_KEY': 'applianceKey',
    'FROM': 'timestampMin',
    'TO': 'timestampMax',
    'ENERGY_CONSUMPTION': 'totalEnergyConsumption',
  },
};

function ApplianceCyclesMonth() {
  const mounted = useRef(false)
  const previousMonth = useRef(endOfMonth(subMonths(new Date(), 1)));
  const { dateSelected, setDateSelected } = useContext(DateSelectedContext);

  useEffect(() => {
    if (mounted.current) return;

    if (isAfter(dateSelected, previousMonth.current)) {
      setDateSelected(previousMonth.current);
    }

    mounted.current = true;
  }, [dateSelected, setDateSelected]);

  const sensor = useContext(SensorContext);
  const isPageUnavailable = usePageUnavailable();

  const [validationError, setValidationError] = useState('');
  const [isValid, setIsValid] = useState(false);

  const sensorId = sensor.id || SENSOR_ID_PLACEHOLDER;
  const month = format(dateSelected, 'MM');
  const year = format(dateSelected, 'yyyy');

  const sensorFirstEventDate = useRef(null);
  const previousTwoMonths = useRef(endOfMonth(subMonths(new Date(), 2)));

  const PAYLOAD_MAP = PAYLOAD_PROP_NAMES[sensor.type] || PAYLOAD_PROP_NAMES.DEFAULT;
  const APPLIANCES = sensor.type === 'LOW_RESOLUTION_METER' ? LOW_RES_APPLIANCES : REGULAR_APPLIANCES;

  useEffect(() => {
    if (!sensor.id) {
      setIsValid(false);
      return setValidationError(SENSOR_NOT_SELECTED_ERROR_MESSAGE);
    }
 
    if (sensor?.body?.data?.data?.first_event) {
      sensorFirstEventDate.current = new Date(sensor.body.data.data.first_event);
      if (isBefore(endOfMonth(dateSelected), sensorFirstEventDate.current)) {
        setIsValid(false);
        return setValidationError(
          MINIMUM_MONTH_ERROR_MESSAGE(
            format(sensorFirstEventDate.current, 'MMM yyyy')
          )
        );
      }
    }

    if (isAfter(dateSelected, previousMonth.current)) {
      setIsValid(false);
      return setValidationError(REPORT_LAST_MONTH_ERROR_MESSAGE);
    }

    setIsValid(true);
    return setValidationError('');
  }, [dateSelected, sensor.id, sensor]);

  const response = useQuery(
    ['applianceCycles', sensorId, month, year],
    () => axios.get(`/sensors/${sensorId}/sm/disag/cycles/monthly?month=${month}&year=${year}`),
    {
      enabled: isValid,
      retry: 0,
    }
  );

  const overallData = useMemo(() => {
    if (!response?.data?.data) return;

    return response?.data?.data?.reduce((acc, current) =>
      ({...acc,
        totalConsumption: acc.totalConsumption + current[PAYLOAD_MAP.TOTAL_CONSUMPTION],
        totalCycles: acc.totalCycles + ((sensor.type === 'LOW_RESOLUTION_METER' ? current?.cycles?.length : current?.totalCycles) ?? 0)
      }), { totalConsumption: 0, totalCycles: 0 }) || { totalConsumption: 0, totalCycles: 0 }
  }, [response, sensor.type, PAYLOAD_MAP]);

  const getData = (data) => {
    const dataArray = data.data.map(appliance => {
      const key = appliance[PAYLOAD_MAP.APPLIANCE_KEY];

      const cycles = appliance.cycles.map(cycle => ({
        key: `${key}-${cycle[PAYLOAD_MAP.FROM]}`,
        startingTime: format(new Date(cycle[PAYLOAD_MAP.FROM]), 'dd/MM/yyyy hh:mm a'),
        endingTime: format(new Date(cycle[PAYLOAD_MAP.TO]), 'dd/MM/yyyy hh:mm a'),
        consumption: cycle[PAYLOAD_MAP.ENERGY_CONSUMPTION],
        consumptionPercent: Math.round(appliance[PAYLOAD_MAP.TOTAL_CONSUMPTION] / cycle[PAYLOAD_MAP.ENERGY_CONSUMPTION] * 100).toFixed(2),
      }));

      return {
        ...appliance,
        applianceKey: key,
        title: APPLIANCES[key]?.label ?? key,
        color: APPLIANCES[key]?.color ?? APPLIANCES.others.color,
        totalConsumption: appliance[PAYLOAD_MAP.TOTAL_CONSUMPTION],
        totalConsumptionPercent: Math.round(appliance[PAYLOAD_MAP.TOTAL_CONSUMPTION] / overallData.totalConsumption * 100).toFixed(2),
        cycles,
        totalCycles: sensor.type === 'LOW_RESOLUTION_METER' ? cycles?.length ?? 0 : appliance.totalCycles,
      }
    });

    return dataArray;
  };

  const disableBackward = isBefore(startOfMonth(dateSelected), sensorFirstEventDate.current);
  const disableForward = isAfter(dateSelected, previousTwoMonths.current);

  if (isValid && isPageUnavailable) {
    return <Unavailable pageName={isPageUnavailable} />
  }

  return (
    <Wrapper>
      <ContentWrapper>
        <PageTitle
          filter={
            <div>
              <DateArrow
                dir='backward'
                disabled={response.isLoading || disableBackward}
                onClick={() => setDateSelected(subMonths(dateSelected, 1))}
              />
              <DatePicker
                autoOk
                id="date-picker"
                label="Month"
                value={dateSelected}
                onChange={date => setDateSelected(date)}
                variant="inline"
                format="MMM yyyy"
                views={['year', 'month']}
                openTo="month"
                error={Boolean(validationError)}
                maxDate={previousMonth.current}
                minDate={sensorFirstEventDate.current}
                last="true"
              />
              <DateArrow
                dir='forward'
                disabled={response.isLoading || disableForward}
                onClick={() => setDateSelected(addMonths(dateSelected, 1))}
              />
            </div>
          }
        >
          Appliance cycles - Month
        </PageTitle>
        <Typography paragraph>
        Returns the quantity of appliance cycles that happened in the period.
        </Typography>
        {validationError && (
          <Typography paragraph color="error">
            {validationError}
          </Typography>
        )}
        {response.isLoading && <CenteredLoader />}
        {!!(!validationError && response?.data?.data?.length) && (
          <CardContainer container spacing={2}>
            <Card
              xs={12}
              sm={6}
              title="Total Consumption"
              data={`${new Intl.NumberFormat(sensor.isoLocaleCode).format(
                overallData.totalConsumption
              )} Wh`}
            />
            <Card
              xs={12}
              sm={6}
              title="Total Cycles"
              data={`${overallData.totalCycles}`}
            />
          </CardContainer>
        )}
        {!!(!validationError && response?.data?.data?.length) && (
          <Cycles data={getData(response.data)} showConsumption={sensor.type === 'LOW_RESOLUTION_METER'} />
        )}
        {!response?.data?.data?.length && (
          <Typography paragraph color="error">
            {NO_DATA_FOUND_ERROR_MESSAGE}
          </Typography>
        )}
      </ContentWrapper>
      <JsonTab
        endpoint={`GET /sensors/${sensorId}/sm/disag/cycles/monthly?month=${month}&year=${year}`}
        response={response.data && response.data.data}
        isLoading={response.isLoading}
        isError={response.isError}
        isSuccess={response.isSuccess}
        isIdle={response.isIdle}
      />
    </Wrapper>
  );
}

export default ApplianceCyclesMonth;
