import score1Img from '../../images/scores/1.png';
import score1HalfImg from '../../images/scores/1-half.png';
import score2Img from '../../images/scores/2.png';
import score2HalfImg from '../../images/scores/2-half.png';
import score3Img from '../../images/scores/3.png';
import score3HalfImg from '../../images/scores/3-half.png';
import score4Img from '../../images/scores/4.png';
import score4HalfImg from '../../images/scores/4-half.png';
import score5Img from '../../images/scores/5.png';

const SCORE_ICONS = {
  '1': score1Img,
  '1-half': score1HalfImg,
  '2': score2Img,
  '2-half': score2HalfImg,
  '3': score3Img,
  '3-half': score3HalfImg,
  '4': score4Img,
  '4-half': score4HalfImg,
  '5': score5Img,
};

function scoreIcon(score) {
  const wholeInteger = parseInt(String(score));
  const remainder = score - wholeInteger;
  if (remainder >= 0.5 && remainder <= 0.9) {
    return SCORE_ICONS[`${wholeInteger}-half`];
  }

  return SCORE_ICONS[String(wholeInteger)];
}

function scoreColor(score) {
  if (score !== null) {
    if (score >= 2.5) {
      if (score >= 3) {
        return '#6AB42D';
      } else {
        return '#FF7300';
      }
    }
    return '#E30100';
  }

  return '#B9B9B9';
}

function scorePercent(percent, rawValue) {
  if (!rawValue) {
    return percent <= 50 ? `Top ${percent}%` : `Bottom ${Math.max(1, 100-percent)}%`;
  }
  
  return `${percent > 0 ? '+' : ''}${percent.toFixed(2)}%`;
}

export { scoreIcon, scoreColor, scorePercent };
