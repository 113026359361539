import styled from 'styled-components';

const VOLTA_GREEN = '#6AB42D';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  & * {
    font-family: 'Ubuntu', 'Inter', sans-serif !important;
    font-size: 1rem;
    color: #545454;
    padding: 0;
    margin: 0;
  }
`;

export const Title = styled.strong`
  font-size: 1.6rem;
  font-weight: 700;
  color: #242424;
  padding: 10px 0;
`;

export const Score = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;

  & > img {
    padding: 0 8px 6px;
  }
`;

export const Value = styled.div`
  color: ${props => props.color || VOLTA_GREEN};
  span {
    font-size: 1.5rem;
    font-weight: 700;
    padding-right: 2px;
    color: ${props => props.color || VOLTA_GREEN};
  }
`;

export const ScoreTitle = styled.strong`
  padding: 10px 0;
  text-align: center;
`;

export const ScoreDataWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
`;

export const ScoreData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 10px;
  min-width: 350px;
`;

export const ScoreStatistic = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px;
  padding-left: 10px;
  margin-bottom: 10px;
  border: 1px solid #DADADA;
  border-radius: 6px;

  ${({ noBorder }) => noBorder && `
    border-width: 0;
    padding-top: 0;
    margin-bottom: 5px;
    justify-content: end;

    & > p {
      font-style: italic;
    }
  `};

  & > div {
    display: flex;
    align-items: center;
  }
`;

export const ScorePercent = styled.div`
  display: inline-block;
  font-size: 0.8rem;
  font-weight: 600;
  color: #FFFFFF;
  background-color: ${props => props.color || VOLTA_GREEN};
  line-height: 34px;
  padding: 0 15px;
  margin-left: 10px;
  border-radius: 6px;
`;

export const EnergySavingRecommendations = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 10px 10px;
`;

export const Recommendation = styled.div`
  display: flex;
  align-items: start;
  width: 350px;
  margin-bottom: 15px;

  img {
    min-width: 76px;
    margin-right: 10px;
  }

  div {
    display: flex;
    flex-direction: column;

    p {
      padding-top: 5px;
    }
  }
`;
